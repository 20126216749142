body {
  margin: 0;
  font-family: "Roboto Condensed", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

code {
  font-family: Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  padding: .2rem .4rem;
  font-size: 90%;
  color: #bd4147;
  background-color: #f8f9fa;
  border-radius: .25rem;
}

* {
  box-sizing: border-box;
}